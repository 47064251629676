export default {
    data() {
        return {
            en: {
                //Cashier + CashierOptions
                cashier_cashierTitle: "Cashier",
                cashier_managerMenuTitle: "Manager Menu",
                cashier_promotionsTitle: "Promotions",
                cashier_cashierOptionsTitle: "Cashier Options",
                cashier_managerOptionsTitle: "Manager Options",
                sign_In: "Sign In",
                sign_Out: "Sign Out",
                cashierAmount: "Cashier Amount",
                lblBlocked: "Blocked",
                btnBlockCashier: "Block Cashier",
                btnUnblockCashier: "Unblock Cashier",
                btnCashierCheck: "Cashier Check",
                btnAccInfo: "Ticket Info",
                btnCloseAcc: "Close Account",
                btnCloseAccs: "Close Accounts",
                btnRegisterFill: "Register Fill",
                btnRegisterDrop: "Register Drop",
                btnTerminalStatus: "Terminal Status",
                btnReports: "Reports",
                btnPaidAccounts: "Paid Accounts",
                btnInformesGistra: "GISTRA Report",
                btnNavision: "Navision",
                btnAddCredits: "Add Credits",
                btnNewPin: "Create Account",
                drawerAmount: "INSERT INITIAL CASHIER AMOUNT",
                drawerFinalAmount: "INSERT FINAL CASHIER AMOUNT",
                drawerFillCashier: "INSERT FILL CASHIER AMOUNT",
                drawerDropCashier: "INSERT DROP CASHIER AMOUNT",
                drawnerBtnNewPin: "INSERT NEW PIN AMOUNT",
                drawnerCashierCheck: "INSERT CASHIER CHECK AMOUNT",
                btnEdit: "Edit",
                btnDelete: "Delete",
                btnView: "View",
                btnClose: "Close",
                btnOk: "Ok",
                btnTest: "Test",
                btnAdd: "Add",
                btnReturn: "Return",
                btnBack: "Back",
                btnCancel: "Cancel",
                btnLogin: "Login",
                btnYes: "Yes",
                btnNo: "No",
                btnExit: "Exit",
                btnRefresh: "Refresh",
                btnReset: "Reset",
                btnWait: "Please wait...",
                btnSave: "Save",
                btnConfirm: "Confirm",
                btnDiscard: "Discard",
                btnApply: "Apply",
                btnReprintTicket: "Reprint Ticket",
                lblPassword: "Password",
                lblConfirmPassword: "Confirm Password",
                lblValue: "Value",
                lblNewValue: "New Value",
                lblReserveValue: "Reset Reserve",
                waiting: "Waiting",
                checkingLicense: "Checking License",
                cmdStartCashier: "Start Cashier",
                cmdFinshCashier: "Finish Cashier",
                cmdCashierBlock: "Cashier Block",
                cmdCashierUnblock: "Cashier Unblock",
                cmdAccountInfo: "Cashier Account Info",
                cmdCloseAccounts: "Cashier Close Accounts",
                cmdCloseAccountsOK: "Closing Ticket",
                cmdCloseAccountsCancel: "Cancel Ticket",
                cmdCashierRegisterFill: "Cashier Register Fill",
                cmdCashierRegisterDrop: "Cashier Register Drop",
                cmdCashierAddNewPin: "Cashier Add New Pin",
                messageSignOutCashier: "Are you sure you want to close Cashier?",
                messageBlockCashier: "Are you sure you want to block Cashier?",
                messageUnblockCashier: "Are you sure you want to Unblock Cashier?",
                messageErroBlockUnblockCashier: "Only the Cashier User can block or unblock Cashier",
                ticketTitle: "Ticket - Enter Ticket Number",
                authenticationTitle: "Authentication",
                columnTicket: "Ticket",
                lblSearchAccount: "Search by Account",
                reprintAccount_Title: "Reprint Account",
                btnReprint: "Reprint",
                messageSessionClose: "Session is not open...",
                lblWarning: "Warning",
                cashier_Data: "Cashier Data",
                columnStartCash: "Start Cash",
                columnTotalFill: "Total Fill",
                columnTotalDrop: "Total Drop",
                columnTotalAccountIn: "Total Account In",
                columnTotalAccountOn: "Total Account Out",
                columnTotalCompAccount: "Total Complementary Account",
                columnFinalCash: "Final Cash",
                columnData: "Data",
                messageSignOutPart1: "Only user[",
                messageSignOutPart2: "] can do the SignOut",

                //ManagerOptions
                btnUnblockMachines: "Unblock Machines",
                btnBlockMachines: "Block Machines",
                btnAutoBlockMachines: "Auto Machines",
                messageBlockMachines: "Do you really want to block machines?",
                messageUnblockMachines: "Do you really want to unblock machines?",
                btnCloseSession: "Close Session",
                btnOpenSession: "Open Session",
                messageOpenSession: "Do you really want to Open Session?",
                messageCloseSession: "Do you really want to Close Session?",
                messageCloseSessionAlert: "Please click on 'Print Report'\n" +
                    "button to print session report",

                messageOpenSessionAlert: "If the last session report was not\n" +
                    "printed, doing this action will\n" +
                    "cause this data to be lost and will\n" +
                    "not be able to reprint later\n\n\n\n" +
                    "Do you want to continue without print?",
                btnCopyReport: "Copy Report",
                btnPartialReport: "Partial Report",
                btnGenerateReport: "Generate Report",
                btnPrintReport: "Print Report",
                messageCashierOpen: "The cashier session is open, please sign out of the session!",

                //Header
                messageHasEmailToSend: "There are files to be sent by Email Sender",
                massageRestartServer: "Do you really want to restart Megapot Server?",
                cmdRestartServer: "Restart Server",
                messageCloseServer: "Do you really want to close Megapot Server?",
                cmdServerStop: "Close Application",
                messageSignOut: "Do you really want to Sign Out?",
                cmdBlockCommunication: "Block Communication",
                messageBlockCommunication: "Do you really want to Block Communication?",
                cmdUnblockCommunication: "Unblock Communication",
                messageUnblockCommunication: "Do you really want to Unblock Communication?",
                messageAllMachinesConnect: "All machines will connect from the server.",
                messageAllMachinesDisconnect: "All machines will disconnect from the server.",
                changePassword: "Change Password",
                btnAddCash: "Add Cash",
                btnLock: "Lock Menu",
                btnResetClient: "Reset Client",
                btnCloseClient: "Close Client",
                language: "Language",
                messageTurnOffServer: "This action will shut down the Server.",
                languageName: "English",

                //Settings
                btnLogoff: "LogOff",
                btnForcedLogOff: "Forced LogOff",
                btnRestart: "Restart",
                btnShutdown: "Shutdown",
                messageProcessLogOff: "Do you really want to logoff?",
                messageProcessForcedLogOff: "Do you really want to Force logoff?",
                messageProcessRestart: "Do you really want to restart?",
                messageProcessShutdown: "Do you really want to shutdown?",

                //Langauge
                messageSetLanguage: "Changing Language...",

                //Login
                messageErrorLogin: "Wrong login or password",
                messageErrorPassword: "Incorrect password!",
                messageErrorByPermission: "User does not have permission",

                //Reset Value
                messageErrorValue: "Value must be from",
                messageErrorReserveValue: "Reserve value must be from",
                messageErrorGrandValue: "Value [Grand] must be from",
                messageErrorMinorValue: "Value [Minor] must be from",
                messageErrorMajorValue: "Value [Major] must be from",
                to: "to",
                cmdResetBonus: "Reseting values",
                columnChange: "Change",
                lblNotChanged: "Not Changed",
                confirmReset: "Set new Values",
                messageConfirm: "Are you sure?",
                lblValueWillBe: "Value will be",
                lblResetType: "Reset Type",
                lblPayNoConnection: "Payment Without Communication" ,
                lblAdjustmentSystem: "Adjustment due to system failure",
                lblPaidValue: "Paid Value",
                messageWarningResetValuePartOne: "When selecting this option, a new payment of ",
                messageWarningResetValuePartTwo: " will be recorded. This will cause the last payment to be replaced by this value.",

                //Terminals
                terminalsTitle: "Terminals",
                terminalInfoTitle: "Terminal Information",
                lblCounter_Title: "Counters",
                lblGameData: "Game Data",
                lblTotalTerminals: "Total Terminals",
                lblLoggedTerminals: "Logged Terminals",
                lblFinishedLogin: "Finished Login",
                lblGamesAvailable: "Games Available",
                lblInLogin: "In Login Process",

                //columnTerminals: 
                columnTerminalNumber: "Terminal Number",
                columnRealMachNum: "Real Machine Number",
                columnTerminalIP: "Terminal IP",
                columnLocalization: "Localization",
                columnGameName: "Game Name",
                columnGameGroup: "Game Group",
                columnGameNumber: "Game Number(Last Play)",
                columnProgressiveGroup: "Progressive Group",
                columnMoney: "Money",
                columnSoftwareVersion: "Software Version",
                columnCredit: "Credit",
                columnTotalCoinIn: "Total Coin In",
                columnTotalCoinOut: "Total Coin Out",
                columnTotalGamesPlayed: "Total Games Played",
                columnTotalCashIn: "Total Cash In",
                columnTotalCashOut: "Total Cash Out",
                columnTotalHandPays: "Total HandPays",

                //CopyReport: 
                availableFiles: "Available Files",
                addFiles: "Added Files to be managed",
                messageNoFiles: "No Files Found",
                messageLargeFiles1: "Selected files size: ",
                messageLargeFiles2: "Files larger than 500 MB can take a while to copy",
                messageLargeFiles3: "Note: Files will be compressed before copy",
                btnViewErrors: "View Errors",
                btnResend: "Resend",
                btnCopyFile: "Copy File",

                //Email
                emails: "Emails",
                email: "Email",
                btnAccountConfig: "Account Config",
                btnSendFilesNow: "Send Files Now",
                btnSizeConfig: "File Size Config",
                messageSetMaxFileSize: "Saving Max File Size",
                lblSearchEmail: "Search by Email...",
                account: "Account",
                SMTPServer: "SMTP Server",
                SMTPAuthenticate: "SMTP Authenticate",
                SMTPPort: "SMTP Port",
                useStartTLS: "Use Start TLS (SSL)",
                SMTPTimeout: "SMTP Timeout",
                btnTestSend: "Test Send",
                tabOthers: "Others",
                tabRetry: "Retry",
                tabNotification: "Notification",
                EmailNotificationId: "Email NotificationId",
                EmailNotificationCode: "Code",
                EmailNotification: "E-mail",
                automaticEmailSender: "Automatic Email Sender",
                mobileInternet: "Mobile Internet",
                serialPortName: "Serial Port Name",
                commDialUpName: "Comm Dial-up Name",
                firstRetry: "First retry interval",
                secondRetry: "Second retry interval",
                thirdRetry: "Third retry interval",
                subsequentRetry: "Subsequent retry interval",
                delayNotification: "Delay notification",
                expirationTimeout: "Expiration timeout",
                reportListTitle: "Report List",
                emailConfigTitle: "E-mail Config",
                msgErrorEmail: "E-mail not configured",
                messageSendManualEmail: "Sending e-mails...",
                messageTestEmail: "Testing e-mail...",

                //Colums name
                columnName: "Name",
                columnEmail: "E-mail",
                columnActions: "Actions",
                columnField: "Field",
                columnValue: "Value",
                columnPlatform: "Platform",

                //Time
                hours: "Hours",
                minutes: "Minutes",
                seconds: "Seconds",
                days: "Days",

                //Panels
                panelsTitle: "Panels",
                lblSearchPanels: "Search by Panel...",
                lblSearchFooter: "Search by Footer...",
                columnText: "Text",
                btnDisplayName: "Display Panel Name",
                messageDisplayName1: "This action will display the panel's name",
                messageDisplayName2: "Are you sure you want to proceed?",
                messageDisplayNameCMD: "Sending display name on every Panel...",
                messageDisplayNameFalse1: "A Message with the Panel name is currently displaying in <b>All</b> Panels.",
                messageDisplayNameFalse2: "<b>If you want to stop this display, please press 'Cancel Display' button",
                btnCancelDisplay: "Cancel Display",
                btnSetGroups: "Set Groups",
                btnSetFooterPlaylist: "Set Footer Playlist",
                btnSetPlaylist: "Set Playlist",
                btnDeletePanel: "Delete Panel",
                processDeletePanelPart1: "Are you sure you want to <b>delete</b> your Panel <b>(",
                processDeletePanelPart2: ")</b>? This action cannot be undone.",
                messageDeletePanel: "Deleting panel...",
                processDeletePanelTitle: "Deleting Panel",
                btnRestartPanel: "Restart Panel",
                processRestartPanelPart1: "Are you sure you want to <b>Restart</b> your Panel <b>(",
                processRestartPanelPart2: ")</b>? This action cannot be undone.",
                messageRestartPanel: "Restating panel...",
                processRestartPanelTitle: "Restating Panel",
                btnShutdownPanel: "Shutdown Panel",
                processShutdownPanelPart1: "Are you sure you want to <b>Shutdown</b> your Panel <b>(",
                processShutdownPanelPart2: ")</b>? This action cannot be undone.",
                messageShutdownPanel: "Shutting Down panel...",
                processShutdownPanelTitle: "Shutting Down Panel",
                btnResetFactoryPanel: "Reset Factory Panel",
                processResetFactoryPanelPart1: "Are you sure you want to <b>Reset Factory </b> your Panel <b>(",
                processResetFactoryPanelPart2: ")</b>? This action cannot be undone.",
                messageResetFactoryPanel: "Resting panel...",
                processResetFactoryPanelTitle: "Resting Panel",

                //Coluns name
                columnVersion: "Version",
                columnPanel: "Panel",
                columnCommVersion: "CommVersion",
                columnStatus: "Status",
                lblConnected: "Connected",
                lblDisconnected: "Disconnected",

                //Progressive
                progressive_Title: "Progressive",
                lblProgressive: "Progressive",
                lblProgressiveDNG: "LAP",
                multiProgressives_Title: "MultiProgressives",
                multiProgressive_Title: "MultiProgressive",
                progressive_TabName: "Progressive Data",
                machines_Title: "Machines",
                lblSearchIPorMachNum: "Search by IP or MachNum...",
                btnResetProgressive: "Reset Progressive",
                btnShowProgressive: "Show Progressive",
                btnDeleteTerminal: "Delete Terminal",
                btnPaymentTerminal: "Payment Terminal",
                btnOpenMonitor: "Open Monitor",
                lblSearchProgressive: "Search by Progressive",
                lblSearchGames: "Search by Games",
                lblSearchStatus: "Search by Status",
                lblSearchTerminalInNone: "Terminals in NONE",
                lblSearchAllProgressive: "All Progressives",
                lblSearchMultiProgressive: "Search by MultiProgressive",
                lblSearchAllInThisProgressive: "All in this Progressive",
                lblTypeProgressive: "Type Progressive",
                btnMoveProgressive: "Move Terminal to Progressive",
                switchSelectAll: "Select All",
                messageSetTerminalProgressive: "Moving Progressive(s) ...",
                messagePaymentTerminal: "Processing terminal(s) payment...",
                messageOpenTerminal: "Sending open Terminal(s) ...",
                messageDeleteTerminal: "Deleting Terminal(s) ...",
                messageNoTerminalsAvailable: "No Terminals Available",
                messageBtnOkMoveTermnalToProgressive: "Select a terminal to release the button",
                messageBtnShowTerminalOptions: "Terminal Options",
                messageBtnShowClientOptions: "Client Options",
                massageConfigTerminalOptionPayment: "Do you want to make payment for this machine?",
                massageConfigTerminalOptionOpen: "Do you want to open the settings for this machine?",
                massageConfigTerminalOptionDelete: "Do you want to delete this machine?",
                lblSelectOption: "Select an Option",
                lblSelectOperator: "Select an Operator",
                lblGroupMachines: "Group Machines",
                lblClosed: "Closed",
                lblOpen: "Open",
                fractioned_Title: "Fractioned",
                lblTableEnginesDataProg: "Engines in this Progressive",
                columnTotalBet: "Total Bet",
                columnPercentage: "Percentage",
                btnBlockClient: "Block Client",
                btnUnblockClient: "Unblock Client",
                msgBlockClientConfirmation: "Do you want to block this client?",
                msgUnblockClientConfirmation: "Do you want to unblock this client?",
                lblWarnings: "Warnings",
                msgWarnings: "There are warnings from server, click here to see them",
                msgDeleteClientConfirmation: "Do you want to delete this client?",
                msgRecoverClientConfirmation: "Do you want to recover this client?",
                btnDeleteClient: "Delete Client",
                btnRecoverClient: "Recover Client",

                //columnNameProg
                columnProgressiveType: "Progressive Type",
                columnProgressiveConfig: "Progressive Config",
                columnConfig: "Config",
                columnCoinValue: "Coin Value",
                columnPlayJackpotSoundOn: "Play Jackpot Sound On",
                columnContribution: "Main Contribution",
                columnEnableIniCont: "Initial Contribution Enabled",
                columnIniContribution: "Initial Contribution",
                columnIniValAcumulated: "Initial Value Acumulated",
                columnLevel: "Level",
                columnIP: "IP",
                columnInitialValue: "Initial Value",
                columnMaxValue: "Max Value",
                columnMinBetToPartipate: "Min Bet to Participate",
                columnMaxProportionalBet: "Max Proportional Bet",
                columnEnableIncSound: "Play Increment Sound",
                columnIncrementSoundValue: "Increment Sound Value",
                columnReserveEnabled: "Reserve Contribution Enabled",
                columnReservePerc: "Reserve Contribution",
                columnReserveValue: "Reserve Value",
                columnReserveIni: "Reserve Start Value",
                columnReserveMax: "Reserve Max Value",
                columnMidasFortuneBonus: "Midas Fortune Bonus",
                columnConfigName: "Configuration Name",
                columnFracName: "Fractioned Name",
                columnMinSort: "Min Sort",
                columnMaxSort: "Max Sort",
                columnConsecutivePrizesSrv: "Server Consecutive Prizes",
                columnMinOccSrv: "Server Min Occupation",
                columnConsecutivePrizesMac: "Machine Consecutive Prizes",
                columnMinGameStartMac: "Machine Min Game Start",
                columnTimeEligibleGame: "Time Continue Eligible Game (sec)",
                columnTimeEligibleExtra: "Time Continue Eligible Extra (sec)",
                columnTimeEligibleBonus: "Time Continue Eligible Bonus (sec)",
                columnTimeoutCancelPrize: "Timeout Cancel Prize (min)",
                columnTimeoutConnection: "Timeout Connection (min)",
                columnTimeoutClosedSession: "Timeout Closed Session (min)",
                columnTimeBetweenDraws: "Min Time Between Draws (min)",
                columnMinGameStartTrg: "Trigger Min Game Start",
                columnMinOccupationTrg: "Trigger Min Occupation",
                columnDecPercToWinAgainSrv: "Dec Perc To Win Again (W)",
                columnActiveMachSrv: "Active Machines (W)",
                columnCoinInSrv: "Coin In (W)",
                columnPercRoom: "Percent Room (W)",
                columnNetMac: "Net (W)",
                columnTimePlayingMac: "Time Playing (W)",
                columnCoinInMac: "Coin Machine (W)",
                columnPercTerm: "Percent Terminal (W)",

                //columnNameMultiProg
                columnMultiProgressiveSettings: "MultiProgressive Settings",
                columnMultiProgressiveType: "MultiProgressive Type",
                columnMultiProgressiveConfig: "MultiProgressive Config",
                columnTotalContribution: "Total Contribution",
                settings: "Settings",

                //Users
                users_Title: "Users",
                user_Title: "User",
                user_tabName: "User Data",
                report_tabName: "Report Data",
                btnAddUser: "Add User",
                btnAddReportLevel: "Add Report Level",
                lblSearchByLogin: "Search by login...",
                lblSearchByName: "Search by Name...",
                messageDeleteUser: "Deleting user...",
                processDeleteUserTitle: "Deleting account...",
                processDeleteUserPart1: "Are you sure you want to <b>delete</b> your account <b>(",
                processDeleteUserPart2: ")</b>? This action cannot be undone.",

                messageDeleteReportLevel: "Deleting reportLevel...",
                processDeleteReportTitle: "Deleting ReportLevel",
                processDeleteReportPart1: "Are you sure you want to <b>delete</b> your ReportLevel <b>(",
                processDeleteReportPart2: ")</b>? This action cannot be undone.",

                //Column User
                columnLastUpdated: "Last Updated",
                columnFunction: "Function",
                columnLogged: "Logged",
                columnNotLogged: "Not Logged",

                //User Manager
                userManager_Title: "User Manager",
                usersManagement: "Users Management",
                cashier_Option: "Cashier",
                technician_Option: "Technician",
                reportView_Option: "Report View",
                manager_Option: "Manager",
                root_Option: "Root",
                lblSelectALevel: "Select a Level",
                messageErrorInvalidName: "Invalid name. Only letters and numbers are allowed.",
                messageUserExist1: "Login [",
                messageUserExist2: "] already exists",
                messageErrorOptions: "Please check at least one option",
                messageErrorOperator: "Please select at least one operator",
                processCreateUser: "Creating user...",
                processEditUser: "Editing user...",
                lblMultipleFunctions: "Multiple Functions",
                lblSessionMangerFunction: "Session Manager",
                lblSearchOrAddTag: "Search or add a Tag",
                lblAddThisTag: "Add this as new tag",
                lblSearchOrAddOperator: "Search or add a Operator",
                lblAddThisOperator: "Add this as new Operator",
                lblSelectALabel: "Press enter to select",
                lblDeselectLabel: "Press enter to remove",
                messageReLogin: "Click Ok to Login with the new password",
                messageChangePassword: "Please update your Password!",

                //Report Level Manager
                reportLevelManager_Title: "Report Access Configurations",
                errorMessageInvalidReport: "Please select at least one Report.",
                messageCreateReport: "Saving report level...",
                messageEditReport: "Editing report level...",

                //Configurations
                configurations_Title: "Configurations",
                deckDatabase_Title: "Database",
                deckEstablishment_Title: "Server Information",
                deckResetSettings_Title: "Options",
                btnLicense: "Server License",                
                btnCopyLogs: "Copy Logs",
                btnCopyCert: "Copy Certificate",
                btnLoadSettings: "Load Settings",
                btnBackup: "Backup",
                btnRestore: "Restore",
                stepLoadFile: "Load File",
                stepCredentials: "Credentials",
                lblTechnicianLogin: "Technician Login",
                lblExecutionCode: "Execution Code",
                lblDatabasePassword: "Database Password",
                errorMessagePasswordTooShort: "Password is too short",
                errorMessagePasswordAtLeast: "Password must have at least 8 characters",
                stepInformation: "Information",
                stepFinish: "Finish",
                messageDownloadConfig: "Downloading configuration, please wait...",
                messageUploadConfig: "Uploading configuration, please wait...",
                messageUploadDatabase: "Uploading database, please wait...",
                errorMessageInvalidExecutionCode: "Invalid Execution code",
                errorMessageInvalidLoginWithExecutionCode: "Invalid Execution code, Username or Password",
                errorMessageInvalidLogin: "Invalid Username or Password",
                messageInstallingConfiguration: "Installing configurations, please wait...",
                messageRequestConfig: "Request settings...",
                messageRequestFiles: "Request Files...",
                messageRequestPenDriveList: "Request PenDrive List",
                messageDoBackupCMD: "Doing backup of logs and database...",
                btnResetServerConfiguration: "Reset Server Configuration",
                btnResetGlobalConfiguration: "Reset Megapot Configuration",
                btnResetClientConfiguration: "Reset Client Configuration",
                messageResetClient: "Do you really want to restart Client Configuration?",
                btnResetBothConfiguration: "Reset Server and Client Configuration",
                btnResetS2SConfiguration: "Reset S2S Settings",
                btnResetBigOsAddress: "Reset BigOS Address",
                btnResetGlobalClient: "Reset Megapot Address",
                btnManageSystem: "Manage System",
                messageRunOsAdmin: "Loading OS administration screen...",
                messageHasEmailSenderPart1: "There is a previous Email Sender configuration.",
                messageHasEmailSenderPart2: "Do you want to keep it?",
                messageHasEmailSenderPart3: "(Obs.:The configuration of the email, if kept, the reports can be sent to the Reportal).",
                messageDiscardEmailSender: "Wait, deleting email sender...",
                messageGenerateLicenseCMD: "Generating Requisition...",
                messageGenerateLicenseAutomaticCMD: "Requesting License...",
                msgCheckAutoLicense: "You have BigOS address configured and can try to generate your license automatically, do you want to continue? If you choose 'NO' you'll be able to do the manual process",
                msgCancelLicenseReq: "Generate new license requisition",
                msgCancelLicenseQuest: "Generating a new license requisition will invalidate any previously opened requisition. Do you want do continue?",
                msgGenNewLicense: "This action will generate a new license requisition, which doesn't cancel the current license, if you have a working one. Do you want do continue?",
                msgDeleteDatabase: "This action will delete the database. Do you want do continue?",
                msgDeletingDatabase: "Deleting Database...",

                //Session
                stepChooseReport: "Choose Report",
                messageChooseReport: "Choose the Type Report",
                btnCurrent: "Current",
                btnBySession: "By Session",
                stepReportType: "Report Type",
                messageReportType: "A new Report will be Generated Select the kind of report do you want to generate.",
                btnFinal: "Final",
                btnPartial: "Partial",
                stepGenerateCSV: "Generate CSV",
                messageGenerateCSV: "Generate CSV by session or Date",
                btnGenerate: "Generate",
                messageReportTypeFinal: "Please wait while server generates final reports...",
                messageReportTypePartial: "Please wait while server generates partial reports...",
                messageInformationBySession: "Please wait while server generates reports by selected session...",
                messageGenerateCSVRequest: "Generating GameReport CSV...",
                btnManualCashierSignOut: "Manual Cashier Sign out",
                lblSearchByTerminal: "Search by Terminal...",
                session_Title: "Session",
                lblStartedAt: "Started at",
                lblClosedAt: "Closed at",
                lblType: "Type",
                lblLastSessionClose: "Last session close",
                lblSessionControl: "Session Control",
                lblSessionControlManual: "Manual",
                lblSessionControlAutomatic: "Automatic",
                lblSessionControlSemiAutomatic: "Semi Automatic",
                lblSearchCashier: "Search by Cashier...",

                //Footer
                serverVersion: "Server Version",
                sessionStatus: "Session Status",

                //Token
                token_Title: "Token",
                token_messageInfo: "Inform the Password bellow to the central to continue",
                token_messageError1: "Incorrect password! you have ",
                token_messageError2: " more try",

                //Auth
                messageAuthReportManager: "To access the reports area, log in as a user enabled for the module.",
                messageErrorNotSet: "is not set for",
                messageLoggedIn: "logged in temporarily",

                //ReportManager
                reportManager_Title: "Reports",

                //Bonus
                bonus_Title: "Bonus",
                bonusData_tabName: "Bonus Data",
                engineData_tabName: "Engines Data",
                lblResetBonus: "Reset Bonus",
                lblTableEnginesData: "Engines in this Bonus",

                //ColumnBonus
                columnMrChampionConfig: "Mr Champion Config",
                columnMannyBucksConfig: "Manny Bucks Config",
                columnMinPlayersMystery: "Min Players to Mystery",
                columnPigBoomConfig: "Pig Boom! Config",
                columnMinPlayersBonus: "Min Players to Bonus",
                columnPrizeValue1: "Prize Value 1",
                columnPrizeValue2: "Prize Value 2",
                columnPrizeValue3: "Prize Value 3",
                columnPrizeMode: "Prize Mode",
                columnTimeToRestart: "Time To Restart (minutter)",

                //IniFront
                iniFront_Title: "Client Configuration",
                lblPreferencialIP: "Preferencial IP",
                lblGlobalIP: "Megapot Address",
                messageErrorIP: "Please enter a valid address",
                messageCreatedIniFile: "Created iniFile, Wait to Restart...",
                messageNoConnection: "No Connection with IP",
                iniFile_Title: "Ini File",
                messageNeedsIPConfig: "FBM Server needs these Configurations to Run, Please configure to use.",
                lblValidatinIP: "Validating IP...",
                lblErrorDeleteFile: "Error in Delete Client File",
                messageSaveBigOsSettings: "Saving BigOS settings, please wait...",

                //IniServer
                iniServer_Title: "Server Configurations",
                iniGlobal_Title: "Megapot Configurations",
                lblCountryCode: "Country Code",
                messageErrorCountryCode: "Select one Country",
                lblDatabaseIP: "Database IP",
                fbmServer_Option: "Run Megapot Server at Windows startup",
                fbmServerWillRestart: "Megapot Server will restart...",
                dingoServer_Option: "Run DINGO-Server at Windows startup",
                dingoServerWillRestart: "DINGO-Server will restart...",
                spMaster_Option: "Run SP-Master at Windows startup",
                lblFillAlesis: "Please fill in Alesis settings",
                btnCheckTime: "Check Interval (Minutes)",
                btnBigosAddress: "BigOS Address",
                checkAutomatic: "Check automatically for new configurations",
                checkAutomaticTerminal: "Send terminal status",
                messageConfiguratorSettingsPart1: "Please note that depending on the environment used the URL may change.",
                messageConfiguratorSettingsPart2: "If you are using SystemConfigurator you need to finish the address with SystemConfigurator, example https://example.com/SystemConfigurator.",
                messageConfiguratorSettingsPart3: "If you are using BigOS without https you need to inform the 9000 port, example http://example.com:9000, if you are using https finish the address with backend, example https://example.com/backend.",
                messageConfiguratorSettingsPart4: "The first part of the address http(s):// is required in all scenarios",

                //GlobalForm
                global_Title: "Megapot Settings",
                isGlobal_Title: "Megapot Progressive Settings",
                isGlobalProgressive: "Use Megapot Progressive",
                globalProgressiveMode: "Megapot Progressive Mode",
                clientOnly_Option: "Client Only",
                serverOnly_Option: "Server Only",
                clientAndServer_Option: "Client and Server",
                lblGlobalProgressiveServerIP: "Megapot Progressive Server IP",
                globalPreferencialIP_Option: "Megapot Preferencial IP Connection",
                anotherLocalIP_Option: "Use Another local IP to connect",

                //GistraForm
                gistra_Title: "Gistra Settings",
                lblServerIP: "Server IP",
                messageErrorSelectLogin: "Select Login",
                lblPort: "Port",
                messageErrorPort: "Select one Port",

                //AlesisForm
                alesis_Title: "Alesis Settings",
                lblSeller: "Vendor ID",
                messageErrorlblSeller: "Vendor ID is Empty",
                lblSqlUser: "SQL User",
                messageErrorlblSqlUser: "SQL User is Empty",
                lblDataBaseName: "Database Name",
                messageErrorlblDataBaseName: "Alesis Database Name is Empty",
                lblSqlPassword: "SQL Password",
                lblConfirmSqlPassword: "Confirm SQL Password",
                messageErrorProvider: "Select one Provider",

                //BigOs
                messageErrorCheckTime: "Value cannot be 0 or less",

                //App
                hideMenuMessage: "Hiding menu...",
                hideShowMessage: "Loading menu...",

                //Promotions 
                menuPromotions_Title: "Menu Promotions",
                validateCoupon_Title: "Validate Coupon",
                generateCoupon_Title: "Generate Coupon",
                couponManagement_Title: "Coupon Management",
                messageGetPromotionsInfo: "Loading promotion info...",
                lblPromoNoAvailable: "No Promotions available",
                messageValidateCoupon: "Validating BarCode...",
                messageErrorCode: "Validating ErrorCode...",
                confirmation_Title: "Confirmation",
                messageUpdateCoupon: "Confirming and Updating Coupon...",
                messageGenerateCoupon: "Generating Coupons...",
                messageFindByVlt: "Confirming VLT...",
                btnRefreshMachines: "Refresh Machines",
                messageRefreshMachines: "Searching for Machines...",


                //ValidateCoupon
                lblCurrentPromotion: "Current Promotion",
                lblStarts: "Starts",
                columnDrawn: "Drawn",
                columnOrder: "Order",
                columnAward: "Award",
                columnCoupon: "Coupon",
                columnDate: "Date",
                btnValidate: "Validate",

                //GenerateCoupon
                lblNoCodeAvailable: "No Code Available",
                lblContactCentral: "Contact Central",
                btnSearchMachine: "Search Machine",
                lblMachineOutOfService: "Machine Out of Service",
                lblCriterionBet: "Criterion + Bet",
                lblAvailableMachine: "Available Machines(s)",
                lblCoupons: "Coupons",

                //CouponManagement
                lblEnterPromotions: "Enter the Promotion Code",
                lblPromotionCode: "Promotion Code",
                lblUserInformation: "User Information",
                lblAddress: "Address",
                lblPhone: "Phone",
                lblIdentification: "Identification",
                columnCouponCode: "Coupon Code",
                columnMachine: "Machine",
                columnPromotion: "Promotion",
                columnCriterion: "Criterion",
                columnPrinted: "Printed",
                btnPrintCoupons: "Print Coupons",
                btnReprintCoupons: "Reprint Coupons",
                btnPrintTest: "Print Test",
                messagePrintTestCoupon: "Printing Test Coupon...",


                //PlayersAccount
                lblStatusFilter: "Status Filter",
                lblCreationDateFilter: "Creation Date Filter",
                free_Option: "Free",
                used_Option: "In Use",
                other_Option: "Other",
                paying_Option: "Paying",
                expired_Option: "Expired",
                created_Option: "Created",
                canceled_Option: "Canceled",
                lblInitialDate: "Initial Date",
                lblFinalDate: "Final Date",
                lblChooseADate: "Choose a Date",
                on_Option: "On",
                lblErrorSelectDates: "Both dates must be selected.",
                columnBalance: "Balance",
                columnExpires: "Expires at",
                playersAccount_title: "Players Account",

                lblNoDataFound: "No Data Found...",
                lblSearchByPin: "Search By Pin...",
                lblLimit: "Limit",

                //Keyboard
                btnBackspace: "Backspace",
                btnClear: "Clear",
                lblOpenKeyboard: "Open Keyboard",

                //Report Level
                lblFinancial: "Financial",
                reportSessionBalanceTot: "Session Balance Total Report",
                reportSessionReport: "Session Report",
                reportPrize: "Prize",
                reportPayOutSummary: "Pay Out Summary Report",
                reportProgressiveGroupReport: "Progressive Group Report",
                lblJackpotGlobal: "Megapot",
                reportStatusReport: "Status Report",
                reportPrizeReport: "Prize Report",
                lblGame: "Game",
                reportGameListReport: "Game List Report",
                reportGameGroupReport: "Game Group Report",
                lblVouchers: "Vouchers",
                reportIssuance: "Issuance",
                reportRedemption: "Redemption",
                reportStatusPaid: "Paid",
                reportStatusOpened: "Opened",
                reportStatusExpired: "Expired",
                reportUnclaimed: "Unclaimed",
                reportCashOut: "Cash Out",
                reportStatus: "Status",
                lblTerminal: "Terminal",
                lblLiability: "Liability",
                reportGameTerminalReport: "Game Terminal Report",
                reportTerminalEventReport: "Terminal Event Report",
                reportGameTerminalCountsReport: "Game Terminal Counts Report",
                reportReconcilationReport: "Reconciliation Report",
                reportReportAccountingFinal: "Report Accounting Final",
                reportRamClearHistReport: "Ram Clear Hist Report",
                reportPlayHistReport: "Play Hist Report",
                reportBillIn: "Bill In",
                lblGenReports: "Gen Reports",
                lblServerReports: "Server Reports",
                reportTerminalSalesReport: "Terminal Sales Report",
                reportReport: "Report",
                reportTerminalAuditReport: "Terminal Audit Report",
                reportAuditReport: "Audit Report",
                reportTerminalReport: "Terminal Report",
                lblMystery: "Mystery",
                lblPigBoom: "Pig Boom!",
                lblMannyBucks: "Manny Bucks",
                reportMysteryPrizeReport: "Mystery Prize Report",
                reportMannyBucksPrizeReport: "Manny Bucks Prize Report",
                reportBonusPrizeReport: "Bonus Prize Report",

                //Report Manager
                bySession: 'By Session',
                byPeriod: "By Period",
                byMachines: "By Machines",
                current: "Current",
                perUser: "per User",
                perCashier: "per Cashier",
                lifeTime: "Life Time",
                summary: "Summary",
                hist: "Hist",
                lblExport: "Export",
                lblSelectType: "Select Type",
                lblSelectDevice: "Select a Device",
                lblFilter: "Filter",
                lblSelectUser: "Select a User",
                lblSelectCashier: "Select Cashier",
                lblSelectMachine: "Select Machine",
                lblCurrentSession: "Current Session",
                lblSelectDate: "Select a Date",
                lblClickToSelect: "Click to Select...",
                lblSelectSession: "Select a Session",
                messageErrorGetReport: "Failed to Get Report",
                checkFieldsReport: "Please check the fields for Generate Report.",
                messageExportReport: "Exporting reports...",

                //Report Name
                ReportFinancialSessionBalanceTotal: "Session Balance Total Report",
                ReportFinancialSessionBalanceParcial: "Session Balance Parcial Report",
                ReportFinancialSessionAccount: "Session Report",
                ReportFinancailSessionDesglose: "Desglose Session Report",
                ReportFinancailSessionOcupation: "Occupation Report",
                ReportFinancailSessionGistra: "Gistra Session Report",
                ReportVoucherLiability: 'Liability',
                ReportVoucherStatus: 'Status',
                ReportVoucherIssuance: "Issuance",
                ReportVoucherRedemption: "Redemption",
                ReportVoucherUnclaimed: "Unclaimed",
                ReportVoucherStatusOpened: "Cash Out",
                ReportVoucherPlaying: "Playing",
                ReportVoucherReport: "Report",
                ReportVoucherStatusExpired: "Voucher Status Expired Report",
                ReportVoucherStatusPaid: "Voucher Status Paid Report",
                ReportProgressiveStatus: "Status Report",
                ReportProgressivePrize: "Prize Report",
                ReportProgressivePayOut: "Pay Out Summary Report",
                ReportProgressiveGroup: "Progressive Group Report",
                ReportCashierTerminalSales: "Terminal Sales Report",
                ReportCashierReport: "Report",
                ReportCashierTerminalAudit: "Terminal Audit Report",
                ReportCashierAudit: "Audit Report",
                ReportCashierTerminalReport: "Terminal Report",
                ReportCashierCashierAudit: "CashierAuditReport",
                ReportTerminalGameReport: "Game Terminal Report",
                ReportTerminalEvent: "Terminal Event Report",
                ReportTerminalGameCounts: "Game Terminal Counts Report",
                ReportTerminalReconciliation: "Reconciliation Report",
                ReportTerminalAccountingFinal: "Report Accounting Final",
                ReportTerminalRamClearHist: "Ram Clear Hist Report",
                ReportTerminalPlayHist: "Play Hist Report",
                ReportTerminalBillInHistory: "Bill in History",
                ReportTerminalBillInSummary: "Bill in Summary",
                ReportGameList: "Game List Report",
                ReportGameGroup: "Game Group Report",
                ReportJackGlobalStatus: "Status Report",
                ReportJackGlobalPrize: "Prize Report",
                ReportBonusMysteryPrize: "Mystery Prize Report",
                ReportBonusPigBoomPrize: "Pig Boom Prize Report",

                //License
                license_Title: "License",

                //Debug
                debug_Title: "Debug",
                pigBoom_card_Title: "PigBoom! Controls",
                mystery_card_Title: "Mystery Controls",
                multiProgressive_card_Title: "MultiProgressive Controls",
                btnForcePigBoom: "Force PigBoom!",
                btnForceMystery: "Force Mystery",
                btnForceMultiProgressive: "Force MultiProgressive Level",
                btnClearForceLevelList: "Clear Force Level List",
                lblAllPigBoomGroups: "All Pig Boom! Groups",
                lblAllMysteryGroups: "All Mystery Groups",
                lblAllMannyBucksGroups: "All Manny Bucks Groups",
                lblAllProgressivesGroups: "All Progressive Groups",
                lblSelectMultiProgressive: "Select MultiProgressive",
                lblSelectPigBoom: "Select PigBoom!",
                lblSelectMystery: "Select Mystery",
                lblSelectMannyBucks: "Select Manny Bucks",
                lblSelectLevel: "Select a Level",
                lblSelectProgressive: "Select a Progressive",
                deckPigBoom_Title: "Pig Boom! Controls",
                deckMystery_Title: "Mystery Controls",
                deckMannyBucks_Title: "Manny Bucks Controls",
                deckMultiProgressive_Title: "MultiProgressive Controls",

                //noConnection
                messageNoConnectionFront: "Lost communication with server, trying new connection...",
                lblError: "Error",

                //Loading
                messageServerDidNotRespond: "Server did not respond to command",
                messageServerAttempts: "in 3 attempts",
                messageServerRecive: "Received response from command",
                messageLimitTime: "in limit time",
                messageServerFinishedCMD: "Server finished the command",
                lblSucess: "Success",

                //GlobalProgressive
                globalData_tabName: "Megapot Data",
                lblServerInfo: "Server Info",
                lblGlobalServerInfo: "Megapot Server IP",
                lblNotFound: "Not Found",
                lblConectedServers: "Connected Servers",
                resetGlobalProgTitle: "Set Progressive Value",
                globalProgressive_Title: "Megapot",
                btnShowEstablishment: "Show Operator and Server",
                messageGetOperatorServer: "Operator and Server",
                columnOperator: "Operator",
                columnServerName: "Server Name",
                columnShortName: "Short Name",
                columnAddress: "Address",
                columnCity: "City",
                columnDateUpdate: "Date Update",
                messageInfoDeleteOperator: "By clicking Delete, the system will delete the operator from the database and it will stop working",
                lblNoPrizeFound: "No Pending Prizes Found...",

                //Request File
                lblRequestFile: "Request File",
                messageRestoreBackup: "All your actual bingo data will be lost and replaced by the backup data Do you really want to continue?",
                messageLoadConfig: "Do you really want to load this configuration?",
                btnDownload: "Download",
                btnDonwloadConfig: "Download Configuration!",
                btnDownloadFullConfig: "Download Full Configuration! this will reinstall establishment database with the latest configuration!",
                lblPenDrive: "PenDrive",
                lblUploadFile: "Upload File",
                lblSelectItems: "Select Items",
                messageErrorFileSize: "is larger than 10 mb, please choose a smaller file.",


                //Add Cash
                btnCopy: "Copy",
                messageCopy: "You just copied",
                messageCopyError: "Failed to copy texts",
                messageNewAddCasPassword: "Use this code to create new password",
                messageResetAddCashPassword: "Do you really want to reset the password? Note that you'll need to ask the system administrator for a new password",
                btnResetPassoword: "Reset Password",
                messageValidateHashCode: "Validating Hash Code",
                messageAddCash: "Adding credits...",
                messageSaveHashCode: "Saving Hash Code File...",
                btnResetMach: "Reset Mach",
                btnCreateHashCode: "Create Hash Code",
                messageRequestHashCode: "Requesting Hash Code Files...",

                //Digi
                messageValidateDigi: "Validating digi ip...",
                btnSearch: "Search",

                //Logs
                logs_Title: "Logs",
                screenLog_Option: "Screen Log Option",
                file_Option: "File Log",
                data_Option: "Data Log",
                machineLog_Title: "Machine Log",
                panelLog_Title: "Panel Log",
                globalLog_Title: "Megapot Log",
                clientLog_Title: "Client Log",
                messageShowLogs: "Show Logs",
                messageShowLogsEdt: "Saving logs preferences...",
                savePrefs_Option: "Save Preferences",
                lbl_DaysDeleteOldLogFiles: "Days Delete Old Log Files",

                //Dialog
                dialog_Title: "System",

                //Playlist
                playlistFooter_Title: "Footer Playlist",
                playlist_Title: "Playlist",
                lblSearchByPlaylist: "Search by Playlist...",
                btnAddPlayList: "Add Playlist",
                columnMessage: "Message",
                processCreatePlaylist: "Creating playlist...",
                processEditPlaylist: "Editing playlist...",
                processDeletePlaylistPart1: "Are you sure you want to <b>delete</b> your Playlist <b>(",
                processDeletePlaylistPart2: ")</b>? This action cannot be undone.",
                messageDeletePlaylist: "Deleteting playlist...",
                processDeletePlaylistTitle: "Deleting Playlist",
                lblSelectPlaylist: "Select Playlist",
                messageErrorDate: "Date Already Chosen",
                messageErrorDeletePlaylist: "Error playlist being used in panels",
                messagePlaylistExist1: "Playlist [",
                messagePlaylistExist2: "] already exists",

                footer_Title: "Footer",
                lblSearchByFooter: "Search by Footer...",
                btnAddFooter: "Add Footer",
                processCreateFooter: "Creating Footer...",
                processEditFooter: "Edting Footer...",
                processDeleteFooterPart1: "Are you sure you want to <b>delete</b> your Footer <b>(",
                processDeleteFooterPart2: ")</b>? This action cannot be undone.",
                messageDeleteFooter: "Deleting Footer...",
                processDeleteFooterTitle: "Deleting Footer...",
                messageErrorFooter: "Message already exists!",
                messageErrorDeleteFooter: "Error message being used in playlists",
                messageErrorOption: "Select at least one option",

                //Loading
                lblLoading: "Loading",

                //terminalStatus
                All: "All",
                Logging: "Logging",
                WithCredit: "With Credit",
                Playing: "Playing",
                InPayment: "In Payment",
                AdminScreen: "Admin Screen",
                JackpotPayment: "Jackpot Payment",
                DoorOpen: "Door Open",
                BillError: "Bill Error",
                PrinterErr: "Printer Err",
                MysteryElegible: "Mystery Elegible",
                Blocked: "Blocked",
                PigBoomElegible: "PigBoom Elegible",
                Disconnected: "Disconnected",
                Connected: "Connected",
                FinishedLogin: "Finished Login",
                InLogin: "In Login",
                InFilter: "In Filter",

                //Cashier Dingo
                cashier_DingoTitle: "Dingo Cashier",
                btnManagerIn: "Manager In",
                btnManagerOut: "Manager Out",
                btnCashierIn: "Cashier In",
                btnCashierOut: "Cashier Out",
                btnPower: "Power",
                btnCloseVoucher: "Close Voucher",
                btnCloseVouchers: "Close Vouchers",
                btnVoucherInfo: "Voucher Info",
                btnVoucherViewer: "Voucher Viewer",
                voucher_Title: "Voucher Options",
                register_Title: "Register Options",
                promotions_Title: "Promotions Options",
                manager_Title: "Manager Options",
                btnReportManager: "Report Manager",
                btnUnlock: "Unlock",
                message_Promotions_Info_Coupon: "- Coupon Management button will be available next to Cashier options",
                message_Promotions_Info_Validate: "- Validate Coupon button will be available next to Manager options",
                message_Error_Reprint: "Select at least one coupon to reprint",
                lblEnter_Coupon_Code: "Enter the Coupon Code",

                //Operator
                operator_Title: "Operator",
                messageDeleteOperator: "Are you sure you want to <b>delete</b> this operator?" ,      
                lblConfiguration: "Configuration",
                
                //Client
                client_Title: "Clients",
                servers_Title: "Servers",
                activeClients: "Active Clients",
                inactiveClients: "Inactive Clients",
                blockedClients: "Blocked Clients",
                deletedClients: "Deleted Clients",

                //Client Type
                lblSearchByServer : "Search By Server",
                columnServer: "Server",
                lblParameters: "Parameters",
                
            }
        }
    }
}