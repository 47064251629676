<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import { mapState } from "vuex";
export default {
  mixins: [en, es, nor,swe],
  name: "SimpleKeyboard",
  computed: mapState(["lang"]),
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
    isNumeric: Boolean,
    hasDot: Boolean,
    pin: Number,
    withoutZero: Boolean,
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => this.onKeyPress(button),
      mergeDisplay: true,
      layoutName: "default",
      layout: {
        default: [
          "1 2 3 4 5 6 7 8 9 0",
          "q w e r t y u i o p",
          "{numbers} a s d f g h j k l {backspace}",
          "{shift} z x c v b n m {clear}",
          "{space}",
        ],
        shift: [
          "1 2 3 4 5 6 7 8 9 0",
          "Q W E R T Y U I O P",
          "{numbers} A S D F G H J K L {backspace}",
          "{shift} Z X C V B N M {clear}",
          "{space}",
        ],
        numbers: [
          "! ' ^ + ( ) * ? -",
          "{abc} _ @ ~ < > , . : ; {backspace}",
        ],
      },
      display: {
        "{numbers}": ".?-",
        "{ent}": "Return",
        "{escape}": "Esc ⎋",
        "{tab}": "Tab ⇥",
        "{backspace}": "&nbsp;&nbsp;⌫&nbsp;&nbsp;",
        "{capslock}": "Caps Lock ⇪",
        "{shift}": "Shift ⇧",
        "{controlleft}": "Ctrl",
        "{controlright}": "Ctrl",
        "{altleft}": "Alt",
        "{altright}": "Alt",
        "{metaleft}": "⌘",
        "{metaright}": "⌘",
        "{abc}": "ABC",
        "{clear}": this.translate("btnClear"),
      },
    });

    this.keyboard.setOptions({
      maxLength: this.pin,
    });
    if (this.isNumeric) {
      this.keyboard.setOptions({
        display: {
          "{clear}": this.translate("btnClear"),
          "{bksp}": this.translate("btnBackspace"),
        },

        layout: {
          default: this.getKeyboardNumeric()
        },
        theme: "hg-theme-default hg-layout-numeric numeric-theme",
        maxLength: this.pin,
      });
    }
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    getKeyboardNumeric() {
      let ret = []
      ret.push("7 8 9", "4 5 6", "1 2 3",)
      if (!this.withoutZero) {
        ret.push("0 00 000")
      }
      if (!this.hasDot) {
        ret.push("{bksp} {clear}")
      } else {
        ret.push("{bksp} {clear} .")
      }
      return ret
    },
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") {
        this.handleShift();
        return;
      }
      if (button === "{numbers}" || button === "{abc}") {
        this.handleNumbers();
        return;
      }
      if (button === "{clear}") {
        this.handleClear();
        return;
      }

      if (this.isNumeric) {
        let keyboardInput = this.keyboard.getInput();
        let valor;

        if (this.hasDot) {
          valor = keyboardInput;
        }
        else {
          valor = keyboardInput == "" ? 0 : parseFloat(this.keyboard.getInput().replace(String.fromCharCode(160), ""));
        }

        if (button === "000" || button === "00") {
          if (valor == 0) {
            this.handleClear();
          }
          else {
            this.keyboard.setInput(valor.toString());
          }
        }
        else {
          if (button != "{bksp}") {
            this.keyboard.setInput(valor.toString());
          }
        }
        if (valor == 0) {
          this.keyboard.setInput("");
        }
      }
    },

    handleClear() {
      this.keyboard.clearInput();
    },

    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === "default" ? "shift" : "default";
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },

    handleNumbers() {
      let currentLayout = this.keyboard.options.layoutName;
      let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";
      this.keyboard.setOptions({
        layoutName: numbersToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>